<template>
  <div class="hero-card px-4 pt-6 pb-3 px-lg-10 pt-lg-10 pb-lg-5 mb-4">
    <b-img
      class="img-watermelon"
      src="@/assets/foravila/images/illustrations/watermelon-food.svg"
    >
    </b-img>
    <b-img
      class="img-cloud d-none d-lg-block"
      src="@/assets/foravila/images/illustrations/cloud-food.svg"
    >
    </b-img>
    <b-img
      class="img-cloud-2 d-none d-lg-block"
      src="@/assets/foravila/images/illustrations/cloud-food.svg"
    >
    </b-img>
    <b-img
      class="img-bananas"
      src="@/assets/foravila/images/illustrations/bananas-food.svg"
    >
    </b-img>
    <b-img
      class="img-vegetable"
      src="@/assets/foravila/images/illustrations/vegetable-food.svg"
    >
    </b-img>
    <b-img
      class="d-none d-lg-block img-mushrooms"
      src="@/assets/foravila/images/illustrations/mushrooms-food.svg"
    >
    </b-img>
    <b-img
      class="img-carrot"
      src="@/assets/foravila/images/illustrations/carrot-food.svg"
    >
    </b-img>
    <h1 class="title font-weight-black text-center text-lg-left">
      {{ $t("pages.localFoodService.heroTitle") }}
    </h1>
    <p class="font-weight-normal text-dark text-center text-lg-left mt-3">
      {{ $t("pages.localFoodService.heroTagline") }}
    </p>
    <b-img
      class="main-img"
      src="@/assets/foravila/images/illustrations/food-delivery.svg"
      alt="Pollentia Fruits Logo"
    />
    <div class="d-flex justify-content-center justify-content-lg-start">
      <b-button
        :href="localFoodUrl"
        target="_blank"
        variant="light"
        class="hero-button py-4 px-6"
        >{{ $t("pages.localFoodService.heroButton") }}</b-button
      >
    </div>
    <div
      class="logo-img mt-4 mt-xl-6 d-flex flex-column align-items-center align-items-lg-start"
    >
      <p class="text-dark">
        {{ $t("pages.localFoodService.heroCollaboration") }}
      </p>
      <b-img
        src="@/assets/foravila/images/logos/pollentia-logo.png"
        alt="Pollentia Fruits Logo"
      />
    </div>
  </div>
</template>

<script>
import { BImg, BButton } from "bootstrap-vue";

import "swiper/css/swiper.css";

export default {
  components: {
    BImg,
    BButton
  },

  computed: {
    locale() {
      return this.$i18n.locale.split("-")[0];
    },
    localFoodUrl() {
      return `https://pollentiashop.com/${this.locale}/module/megaaffiliates/url?affiliateurl=foravilarentals`;
    }
  }
};
</script>

<style lang="scss" scoped>
.hero-card {
  z-index: 0;
  position: relative;
  overflow: hidden;
  background: rgb(255, 205, 0);
  background: linear-gradient(
    55deg,
    rgba(255, 205, 0, 0.36) 0%,
    rgba(255, 205, 0, 0.25) 100%
  );
  h1 {
    font-size: 1.6rem;
    z-index: 1;
    color: rgb(43, 43, 43);
    max-width: 60rem;
    @media (min-width: 768px) {
      font-size: 2.5rem;
    }
    @media (min-width: 992px) {
      font-size: 3rem;
      max-width: 50%;
    }
    @media (min-width: 1200px) {
      max-width: 30%;
    }
  }
  p {
    @media (min-width: 992px) {
      font-size: 1.2rem;
      max-width: 50%;
    }
    @media (min-width: 1800px) {
      max-width: 30%;
    }
  }
  .main-img {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 60%;
    z-index: -1;
    @media (min-width: 768px) {
      top: 0%;
      width: 40%;
    }
    @media (min-width: 992px) {
      position: absolute;
      bottom: unset;
      left: unset;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 45%;
    }
    @media (min-width: 1800px) {
      right: 12%;
      width: 40%;
    }
  }
  .hero-button {
    width: 80%;
    font-weight: bold;
    margin-top: 15rem;
    @media (min-width: 768px) {
      margin-top: 20rem;
    }
    @media (min-width: 992px) {
      max-width: 30%;
      margin-top: 2rem;
    }
  }
  .logo-img {
    img {
      max-width: 6rem;
      @media (min-width: 992px) {
        max-width: 10rem;
      }
    }
    p {
      font-size: 0.9rem;
    }
  }
  .img-watermelon {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 4rem;
    @media (min-width: 992px) {
      width: 8rem;
    }
  }
  .img-bananas {
    position: absolute;
    top: 0;
    right: 10%;
    z-index: -1;
    width: 4rem;
    @media (min-width: 992px) {
      width: 8rem;
    }
  }
  .img-vegetable {
    position: absolute;
    bottom: 0;
    left: 10%;
    z-index: -1;
    width: 4rem;
    @media (min-width: 992px) {
      width: 8rem;
      left: 20%;
    }
  }
  .img-mushrooms {
    position: absolute;
    bottom: 0;
    left: 40%;
    z-index: -1;
  }
  .img-carrot {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 4rem;
    @media (min-width: 992px) {
      width: 8rem;
    }
  }
  .img-cloud {
    position: absolute;
    top: 6%;
    left: 20%;
    z-index: -1;
  }
  .img-cloud-2 {
    position: absolute;
    top: 35%;
    left: 40%;
    z-index: -1;
    width: 4rem;
  }
}
</style>
