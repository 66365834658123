import { render, staticRenderFns } from "./foodHero.vue?vue&type=template&id=62ceb15c&scoped=true&"
import script from "./foodHero.vue?vue&type=script&lang=js&"
export * from "./foodHero.vue?vue&type=script&lang=js&"
import style0 from "./foodHero.vue?vue&type=style&index=0&id=62ceb15c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62ceb15c",
  null
  
)

export default component.exports