<template>
  <div id="food-service">
    <food-hero></food-hero>
    <food-categories></food-categories>
    <food-how-it-works></food-how-it-works>
    <foravila-guarantee />
  </div>
</template>

<script>
import FoodHero from "@/views/foravila/services/food/components/foodHero.vue";
import FoodCategories from "@/views/foravila/services/food/components/foodCategories.vue";
import FoodHowItWorks from "@/views/foravila/services/food/components/foodHowItWorks.vue";
import ForavilaGuarantee from "@/views/foravila/services/components/ForavilaGuarantee.vue";

export default {
  components: {
    FoodCategories,
    FoodHero,
    FoodHowItWorks,
    ForavilaGuarantee
  }
};
</script>

<style lang="scss" scoped></style>
