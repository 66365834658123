<template>
  <b-row class=" how-does-it-works">
    <b-col>
      <b-row>
        <b-col>
          <h1
            class="text-center text-capitalize fr-font-weight-super-bold fr-title-mobile-size fr-title-desktop-size"
          >
            {{ $t("pages.localFoodService.howItWorksTitle") }}
          </h1>
          <h4
            class="fr-subtitle-mobile-size fr-subtitle-desktop-size text-center"
          >
            {{ $t("pages.localFoodService.howItWorksSubtitle") }}
          </h4>
        </b-col>
      </b-row>
      <b-row class="mt-6">
        <b-col cols="12" md="4">
          <div class="item text-center h-100">
            <b-img src="@/assets/foravila/images/illustrations/order-food.svg">
            </b-img>
            <p class="h5 mt-4">
              {{ $t("pages.localFoodService.howItWorksText1") }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="4" class="mt-6 mt-md-0">
          <div class="item  text-center h-100">
            <b-img
              src="@/assets/foravila/images/illustrations/prepare-food.svg"
            >
            </b-img>
            <p class="h5 mt-4">
              {{ $t("pages.localFoodService.howItWorksText2") }}
            </p>
          </div>
        </b-col>
        <b-col cols="12" md="4" class="mt-6 mt-md-0">
          <div class="item  text-center h-100">
            <b-img src="@/assets/foravila/images/illustrations/enjoy-food.svg">
            </b-img>
            <p class="h5 mt-4">
              {{ $t("pages.localFoodService.howItWorksText3") }}
            </p>
          </div>
        </b-col>
      </b-row>
      <div class="button d-flex justify-content-center mt-7">
        <b-button
          variant="primary"
          class="py-4"
          :href="localFoodUrl"
          target="_blank"
        >
          {{ $t("pages.localFoodService.mainButton") }}
        </b-button>
      </div>
      <div
        class="d-flex justify-content-center text-success align-items-center mt-3 text-capitalize"
      >
        <feather-icon class="mr-1" icon="CheckCircleIcon" />
        {{ $t("pages.localFoodService.mainButtonTag") }}
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BImg, BButton } from "bootstrap-vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BButton
  }
};
</script>

<style lang="scss" scoped>
.how-does-it-works {
  background-color: white;
  padding: 5rem 2.5rem 2.5rem 2.5rem;
  margin: 5rem 0 5rem 0;
  .item {
    img {
      height: 12rem;
      @media (min-width: 992px) {
        height: 15rem;
      }
    }
  }
  .button {
    a {
      max-width: 40rem;
      color: black !important;
    }
  }
}
</style>
