<template>
  <b-row class="categories">
    <b-col class="swiper-full-width">
      <swiper :options="swiperOptions">
        <div slot="button-prev" class="swiper-button-prev "></div>
        <div slot="button-next" class="swiper-button-next "></div>
        <swiper-slide class="item p-4">
          <div class="text">
            <h3 class="text-dark">
              {{ $t("pages.localFoodService.categoryLocalTitle") }}
            </h3>
            <p class="text-dark mb-4">
              {{ $t("pages.localFoodService.categoryLocalSubTitle") }}
            </p>
          </div>
          <b-button
            :href="localFoodUrl"
            target="_blank"
            class="mt-4"
            variant="light"
          >
            {{ $t("pages.localFoodService.categoryButton") }}
          </b-button>
          <b-img
            src="@/assets/foravila/images/illustrations/fresh-food.svg"
            alt=""
          ></b-img>
        </swiper-slide>
        <swiper-slide class="item item-cl-2 p-4">
          <div class="text">
            <h3 class="text-dark">
              {{ $t("pages.localFoodService.categoryMilkTitle") }}
            </h3>
            <p class="text-dark mb-4">
              {{ $t("pages.localFoodService.categoryMilkSubTitle") }}
            </p>
          </div>
          <b-button
            :href="localFoodUrl"
            target="_blank"
            class="mt-4"
            variant="light"
          >
            {{ $t("pages.localFoodService.categoryButton") }}
          </b-button>
          <b-img
            src="@/assets/foravila/images/illustrations/milk-food.svg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide class="item item-cl-3 p-4">
          <div class="text">
            <h3 class="text-dark">
              {{ $t("pages.localFoodService.categoryBasicsTitle") }}
            </h3>
            <p class="text-dark mb-4">
              {{ $t("pages.localFoodService.categoryBasicsSubTitle") }}
            </p>
          </div>
          <b-button :href="localFoodUrl" target="_blank" variant="light">
            {{ $t("pages.localFoodService.categoryButton") }}
          </b-button>
          <b-img
            src="@/assets/foravila/images/illustrations/food-basics.svg"
            alt=""
          />
        </swiper-slide>
        <swiper-slide class="item item-cl-4 p-4">
          <div class="text">
            <h3 class="text-dark">
              {{ $t("pages.localFoodService.categoryWaterTitle") }}
            </h3>
            <p class="text-dark mb-4">
              {{ $t("pages.localFoodService.categoryWaterSubTitle") }}
            </p>
          </div>
          <b-button :href="localFoodUrl" target="_blank" variant="light">
            {{ $t("pages.localFoodService.categoryButton") }}
          </b-button>
          <b-img
            src="@/assets/foravila/images/illustrations/drinks-food.svg"
            alt=""
          />
        </swiper-slide>
      </swiper>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BImg, BButton } from "bootstrap-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/css/swiper.css";

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 16,
        freeMode: false,
        grabCursor: true,
        loop: true,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.categories {
  .swiper-container {
    padding-top: 6rem;
    @media (min-width: 992px) {
      padding-top: 6rem;
    }
    position: relative;
    .swiper-full-width {
      padding-left: 0;
      padding-right: 0;
    }
    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 2rem;
      color: #ffcd00;
      background-color: rgba(255, 205, 0, 0.15);
      padding: 2rem;
      &::after {
        font-size: 1.2rem;
      }
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: unset;
      right: 5rem;
    }
  }
  .item {
    position: relative;
    width: 300px;
    height: auto !important;
    @media (min-width: 992px) {
      width: 400px;
    }
    background: rgb(255, 205, 0);
    background: linear-gradient(
      55deg,
      rgba(255, 205, 0, 100) 0%,
      rgba(255, 205, 0, 0.75) 100%
    );
    .text {
      max-width: 12rem;
      @media (min-width: 992px) {
        max-width: 16rem;
      }
    }
    img {
      width: 8rem;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
  .item-cl-2 {
    background: rgb(26, 176, 100);
    background: linear-gradient(
      55deg,
      rgba(26, 176, 71, 100) 0%,
      rgba(26, 176, 71, 0.75) 100%
    );
  }
  .item-cl-3 {
    background: rgb(175, 184, 250);
    background: linear-gradient(
      55deg,
      rgba(175, 184, 250, 100) 0%,
      rgba(175, 184, 250, 0.75) 100%
    );
  }
  .item-cl-4 {
    background: rgb(185, 152, 177);
    background: linear-gradient(
      55deg,
      rgba(185, 152, 177, 100) 0%,
      rgba(185, 152, 177, 0.75) 100%
    );
  }
}
</style>
